import { email } from '@/api'
const EMAIL_DATA = () => {
  return {
    emailContent: {
      content: '',
      memo: '',
      tempContent: `<p align="center" style="padding: 0 50px 48px; font-size: 40px; font-weight: 700">제목</p><p width="100%" style="height: 1px; background-color: #c4c4c4"><br></p><p><br></p><p>내용</p>`,
      title: '',
    },
    emailInfo: {
      category: '',
      sendStatus: undefined,
    },
  }
}

const state = {
  email: {
    emailContent: {
      content: '',
      memo: '',
      tempContent: '',
      title: '',
    },
    emailInfo: {
      category: '',
      sendStatus: '',
    },
  },
}

const mutations = {
  INIT_EMAIL_DATA(state) {
    state.email = EMAIL_DATA()
  },
  SET_EMAIL_DATAL(state, detail) {
    state.email = detail
  },
  SET_EMAIL_TEMP(state, temp) {
    state.email.emailContent.tempContent = temp
  },
  SET_EMAIL_CONTENT(state, content) {
    state.email.emailContent.content = content
  },
}

const getters = {}

const actions = {
  FETCH_EMAIL_LIST({}, emailData) {
    return email.emailList(emailData).then((data) => {
      return data
    })
  },
  FETCH_EMAIL_DETAIL({}, emailData) {
    return email.emailDetail(emailData).then((data) => {
      return data
    })
  },
  WRITE_EMAIL_DETAIL({}, emailData) {
    return email.emailWrite(emailData).then((data) => {
      return data
    })
  },
  UPDATE_EMAIL_DETAIL({}, emailData) {
    return email.emailUpdate(emailData).then((data) => {
      return data
    })
  },
  DELETE_EMAIL_DETAIL({}, emailData) {
    return email.emailDelete(emailData).then((data) => {
      return data
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
// export default { actions }
