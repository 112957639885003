<template>
  <div class="nav">
    <router-link
      to="/home"
      class="nav__menu"
      :class="active === 'home' ? 'nav__menu--active' : ''"
      ><div>Home</div></router-link
    >
    <router-link
      to="/user"
      class="nav__menu"
      :class="active === 'user' ? 'nav__menu--active' : ''"
      ><div>회원 관리</div></router-link
    >
    <router-link
      to="/content"
      class="nav__menu"
      :class="active === 'content' ? 'nav__menu--active' : ''"
      ><div>매거진 관리</div></router-link
    >
    <router-link
      to="/routine"
      class="nav__menu"
      :class="active === 'routine' ? 'nav__menu--active' : ''"
      ><div>루틴 관리</div></router-link
    >
    <router-link
      to="/push"
      class="nav__menu"
      :class="active === 'push' ? 'nav__menu--active' : ''"
      ><div>푸시 알림 관리</div></router-link
    >
    <router-link
      to="/email"
      class="nav__menu"
      :class="active === 'email' ? 'nav__menu--active' : ''"
      ><div>이메일 관리</div></router-link
    >
    <router-link
      to="/notice"
      class="nav__menu"
      :class="active === 'notice' ? 'nav__menu--active' : ''"
      ><div>공지사항</div></router-link
    >
    <router-link
      to="/inquiry"
      class="nav__menu"
      :class="active === 'inquiry' ? 'nav__menu--active' : ''"
      ><div>1:1 문의</div></router-link
    >
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
export default {
  name: 'WebNav',
  data() {
    return {
      active: 'home',
    }
  },
  computed: {
    getUrl() {
      let url = window.location.pathname
      return url
    },
  },
  created() {
    this.setNav()
  },

  methods: {
    setNav() {
      const route = useRoute()
      const id = route.params.id
      const nickname = route.params.nickname
      let activeState = ''
      // const route = useRoute()
      // const id = route.params.id
      if (this.getUrl === '/home') activeState = 'home'
      else if (this.getUrl === '/user' || this.getUrl === `/user/${id}`)
        activeState = 'user'
      else if (
        this.getUrl === '/content' ||
        this.getUrl === `/content/${id}` ||
        this.getUrl === `/content/copy/${id}`
      )
        activeState = 'content'
      else if (
        this.getUrl === '/routine' ||
        this.getUrl === `/routine/${id}` ||
        this.getUrl === `/routine/copy/${id}`
      )
        activeState = 'routine'
      else if (
        this.getUrl === '/push' ||
        this.getUrl === `/push/${id}` ||
        this.getUrl === `/push/copy/${id}`
      )
        activeState = 'push'
      else if (
        this.getUrl === '/email' ||
        this.getUrl === `/email/${id}` ||
        this.getUrl === `/email/copy/${id}`
      )
        activeState = 'email'
      else if (this.getUrl === '/notice' || this.getUrl === `/notice/${id}`)
        activeState = 'notice'
      else if (
        this.getUrl === '/inquiry' ||
        this.getUrl === `/inquiry/${id}` ||
        this.getUrl === `/inquiry/search/${nickname}`
      )
        activeState = 'inquiry'
      return (this.active = activeState)
    },
  },
}
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  left: 0;
  right: 0;
  width: 250px;
  min-width: 250px;
  height: calc(100%-60px);
  background: #fff;
  padding-top: 22px;
  div {
    text-align: left;
  }
  &__menu {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    div {
      padding: 0 40px;
    }
    transition: all 0.3s ease-out;
    &:hover {
      div {
        transition: all 0.3s ease-out;
        background: #ececec8e;
      }
    }

    &--active {
      div {
        background: #ececec;
      }
    }
  }
}
</style>
