import { inquiry } from '@/api'

const INQUIRY_DATA = () => {
  return {
    answer: {
      content: null,
      memo: null,
    },
  }
}
const state = {
  inquiry: {
    answer: {
      content: null,
      memo: null,
    },
  },
}

const mutations = {
  INIT_INQUIRY_DATA(state) {
    state.inquiry = INQUIRY_DATA()
  },
  SET_INQUIRY_DETAIL(state, detail) {
    state.inquiry = detail
  },
}

const getters = {}

const actions = {
  FETCH_INQUIRY_LIST({}, inquiryData) {
    return inquiry.inquiryList(inquiryData).then((data) => {
      return data
    })
  },
  FETCH_INQUIRY_DETAIL({}, inquiryData) {
    return inquiry.inquiryDetail(inquiryData).then((data) => {
      return data
    })
  },
  UPDATE_INQUIRY_DETAIL({}, inquiryData) {
    return inquiry.inquiryUpdate(inquiryData).then((data) => {
      return data
    })
  },
  DELETE_INQUIRY_DETAIL({}, inquiryData) {
    return inquiry.inquiryDelete(inquiryData).then((data) => {
      return data
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
// export default { actions }
