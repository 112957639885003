import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import user from './modules/user'
import content from './modules/content'
import routine from './modules/routine'
import push from './modules/push'
import email from './modules/email'
import notice from './modules/notice'
import inquiry from './modules/inquiry'

const store = createStore({
  modules: { auth, user, content, routine, push, email, notice, inquiry },
  plugins: [
    createPersistedState({
      paths: [
        'auth',
        'user',
        'content',
        'routine',
        'push',
        'email',
        'notice',
        'inquiry',
      ],
    }),
  ],
})

export default store
