import axios from 'axios'
import { getCookie } from '@/utils/cookie'
const domain = process.env.VUE_APP_API_URL

// 요청 방법
const request = {
  get(path, header) {
    return axios.get(`${domain + path}`, header).catch((e) => console.error(e))
  },
  post(path, data, header) {
    return axios
      .post(`${domain + path}`, data, header)
      .catch((e) => console.error(e))
  },
  put(path, data, header) {
    return axios
      .put(`${domain + path}`, data, header)
      .catch((e) => console.error(e))
  },
  delete(path, header) {
    return axios
      .delete(`${domain + path}`, header)
      .catch((e) => console.error(e))
  },
}

export const auth = {
  signin(adminData) {
    return request.post('admin/signin', adminData).then(({ data }) => data)
  },
  signout() {
    return request
      .post('admin/signout', '', {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
}
export const home = {
  homeList() {
    return request
      .get('admin/home', {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
}

export const user = {
  userList(userData) {
    let url = `admin/user?`
    if (userData.type) url += `&type=${userData.type}`
    if (userData.query) url += `&query=${userData.query}`
    if (userData.sort) url += `&sort=${userData.sort}`
    return request
      .get(url, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  userDetail(userData) {
    return request
      .get(`admin/user/one?userId=${userData.userId}`, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  userDelete(userData) {
    return request
      .delete('admin/user', {
        data: {
          userId: userData.userId,
        },
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
}

export const content = {
  contentList(contentData) {
    let url = `admin/content?`
    if (contentData.type) url += `&type=${contentData.type}`
    if (contentData.query) url += `&query=${contentData.query}`
    if (contentData.sort) url += `&sort=${contentData.sort}`
    if (contentData.sortField) url += `&sortField=${contentData.sortField}`
    if (contentData.startDate) url += `&startDate=${contentData.startDate}`
    if (contentData.endDate) url += `&endDate=${contentData.endDate}`
    return request
      .get(url, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  contentDetail(contentData) {
    return request
      .get(`admin/content/one?contentId=${contentData.contentId}`, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  contentRelateSearch(contentData) {
    return request
      .get(`admin/content/relate?query=${contentData.query}`, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  contentWrite(contentData) {
    return request
      .post('admin/content', contentData, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  contentUpdate(contentData) {
    return request
      .put('admin/content', contentData, {
        headers: {
          session: getCookie('session'),
          'Content-Type': 'multipart/form-data',
        },
        contentType: 'multipart/form-data',
      })
      .then(({ data }) => data)
  },
  contentDelete(contentData) {
    return request
      .delete('admin/content', {
        data: {
          contentId: contentData.contentId,
        },
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
}

export const routine = {
  routineList(routineData) {
    let url = `admin/routine?`
    if (routineData.type) url += `&type=${routineData.type}`
    if (routineData.query) url += `&query=${routineData.query}`
    if (routineData.sort) url += `&sort=${routineData.sort}`
    if (routineData.sortField) url += `&sortField=${routineData.sortField}`
    if (routineData.startDate) url += `&startDate=${routineData.startDate}`
    if (routineData.endDate) url += `&endDate=${routineData.endDate}`
    return request
      .get(url, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  routineDetail(routineData) {
    return request
      .get(`admin/routine/one?routineId=${routineData.routineId}`, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  routineWrite(routineData) {
    return request
      .post('admin/routine', routineData, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  routineUpdate(routineData) {
    return request
      .put('admin/routine', routineData, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  routineDelete(routineData) {
    return request
      .delete('admin/routine', {
        data: {
          routineId: routineData.routineId,
        },
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
}

export const push = {
  pushList(pushData) {
    let url = `admin/push?`
    if (pushData.type) url += `&type=${pushData.type}`
    if (pushData.query) url += `&query=${pushData.query}`
    if (pushData.sort) url += `&sort=${pushData.sort}`
    if (pushData.sortField) url += `&sortField=${pushData.sortField}`
    if (pushData.startDate) url += `&startDate=${pushData.startDate}`
    if (pushData.endDate) url += `&endDate=${pushData.endDate}`
    return request
      .get(url, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  pushDetail(pushData) {
    return request
      .get(`admin/push/one?pushId=${pushData.pushId}`, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  pushWrite(pushData) {
    return request
      .post('admin/push', pushData, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  pushUpdate(pushData) {
    return request
      .put('admin/push', pushData, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  pushDelete(pushData) {
    return request
      .delete('admin/push', {
        data: {
          pushId: pushData.pushId,
        },
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
}

export const email = {
  emailList(emailData) {
    let url = `admin/email?`
    if (emailData.type) url += `&type=${emailData.type}`
    if (emailData.query) url += `&query=${emailData.query}`
    if (emailData.sort) url += `&sort=${emailData.sort}`
    if (emailData.sortField) url += `&sortField=${emailData.sortField}`
    if (emailData.startDate) url += `&startDate=${emailData.startDate}`
    if (emailData.endDate) url += `&endDate=${emailData.endDate}`
    return request
      .get(url, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  emailDetail(emailData) {
    return request
      .get(`admin/email/one?emailId=${emailData.emailId}`, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  emailWrite(emailData) {
    return request
      .post('admin/email', emailData, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  emailUpdate(emailData) {
    return request
      .put('admin/email', emailData, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  emailDelete(emailData) {
    return request
      .delete('admin/email', {
        data: {
          emailId: emailData.emailId,
        },
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
}

export const notice = {
  noticeList(noticeData) {
    let url = `admin/notice?`
    if (noticeData.type) url += `&type=${noticeData.type}`
    if (noticeData.query) url += `&query=${noticeData.query}`
    if (noticeData.sort) url += `&sort=${noticeData.sort}`
    return request
      .get(url, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  noticeDetail(noticeData) {
    return request
      .get(`admin/notice/one?noticeId=${noticeData.noticeId}`, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  noticeWrite(noticeData) {
    return request
      .post('admin/notice', noticeData, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  noticeUpdate(noticeData) {
    return request
      .put('admin/notice', noticeData, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  noticeDelete(noticeData) {
    return request
      .delete('admin/notice', {
        data: {
          noticeId: noticeData.noticeId,
        },
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
}

export const inquiry = {
  inquiryList(inquiryData) {
    let url = `admin/inquiry?`
    if (inquiryData.type) url += `&type=${inquiryData.type}`
    if (inquiryData.query) url += `&query=${inquiryData.query}`
    if (inquiryData.sort) url += `&sort=${inquiryData.sort}`
    if (inquiryData.sortField) url += `&sortField=${inquiryData.sortField}`
    if (inquiryData.startDate) url += `&startDate=${inquiryData.startDate}`
    if (inquiryData.endDate) url += `&endDate=${inquiryData.endDate}`
    return request
      .get(url, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  inquiryDetail(inquiryData) {
    return request
      .get(`admin/inquiry/one?inquiryId=${inquiryData.inquiryId}`, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  inquiryUpdate(inquiryData) {
    return request
      .post('admin/inquiry', inquiryData, {
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
  inquiryDelete(inquiryData) {
    return request
      .delete('admin/inquiry', {
        data: {
          inquiryId: inquiryData.inquiryId,
        },
        headers: {
          session: getCookie('session'),
        },
      })
      .then(({ data }) => data)
  },
}
