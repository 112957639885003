import { auth, home } from '@/api'
import { setCookie, getCookie } from '@/utils/cookie'

const MAINTAINCE_DATA = () => {
  return {
    maintaince: {
      session: '',
      adminId: '',
    },
  }
}

const state = {
  maintaince: {
    session: getCookie('session') || '',
    adminId: getCookie('adminId') || '',
  },
}

const mutations = {
  INIT_DATA(state) {
    state.maintaince = MAINTAINCE_DATA().maintaince
  },
  SET_LOGIN_RESPONSE(state, loginResponse) {
    state.maintaince.session = loginResponse.session
    state.maintaince.adminId = loginResponse.adminId
  },
}

const getters = {
  GET_ADMIN_SESSION: (state) => state.maintaince.session,
}

const actions = {
  SIGNIN({ commit }, adminData) {
    return auth.signin(adminData).then((data) => {
      if (data.result && data.result === true) {
        let resData = {
          session: data.response.session,
          adminId: data.response.adminId,
        }
        setCookie('session', data.response.session, {
          secure: true,
          expires: 30,
        })
        setCookie('adminId', data.response.adminId, {
          secure: true,
          expires: 30,
        })
        commit('SET_LOGIN_RESPONSE', resData)
      }
      return data
    })
  },
  SINGOUT({}) {
    return auth.signout().then((data) => {
      return data
    })
  },
  FETCH_HOME_LIST({}) {
    return home.homeList().then((data) => {
      return data
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
// export default { actions }
