<template>
  <div class="web-wrap">
    <web-header></web-header>
    <div class="web-wrap__index">
      <web-nav></web-nav>
      <div class="web-wrap__contents">
        <h1 v-text="title"></h1>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import WebHeader from './WebHeader.vue'
import WebNav from './WebNav.vue'
export default {
  name: 'WebTemplate',
  components: { WebHeader, WebNav },
  props: ['title'],
}
</script>

<style lang="scss">
@import '@/assets/scss/init.scss';

.web-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow: auto;
  &__index {
    width: 100%;
    height: 100vh;
    display: flex;
    padding-top: 70px;
  }
  &__contents {
    width: 100%;
    margin-left: 250px;
    padding: 35px;
    background: #f7f8fb;
    overflow: auto;
  }

  h1 {
    font-size: 24px;
  }
}
</style>
