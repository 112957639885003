function setCookie(name, value, options = {}) {
  options = {}
  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString()
  }
  // let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)
  let updatedCookie = name + '=' + value
  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey
    const optionValue = options[optionKey]
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue
    }
  }
  document.cookie = updatedCookie
}

function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line no-useless-escape
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

function deleteCookies(cookieList) {
  const allCookies = ['session', 'adminId']
  if (!cookieList) {
    cookieList = allCookies
  }
  const date = new Date()
  for (let i = 0; i < cookieList.length; i++) {
    document.cookie =
      cookieList[i] + '= ' + '; expires=' + date.toUTCString() + '; path=/'
  }
}

export { setCookie, getCookie, deleteCookies }
