import { createWebHistory, createRouter } from 'vue-router'
import { getCookie } from '@/utils/cookie'

const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: () => import('@/views/auth/LoginPage.vue'),
    meta: { unauthorized: true },
  },
  {
    path: '/home',
    name: 'HomePage',
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/user',
    name: 'UserPage',
    component: () => import('@/views/user/index.vue'),
  },
  {
    path: '/user/:id',
    name: 'UserDetailPage',
    component: () => import('@/views/user/Detail.vue'),
  },
  {
    path: '/content',
    name: 'ContentPage',
    component: () => import('@/views/content/index.vue'),
  },
  {
    path: '/content/:id',
    name: 'ContentDetailPage',
    component: () => import('@/views/content/Detail.vue'),
  },
  {
    path: '/content/:type/:id',
    name: 'ContentCopyPage',
    component: () => import('@/views/content/Detail.vue'),
  },
  {
    path: '/routine',
    name: 'RoutinePage',
    component: () => import('@/views/routine/index.vue'),
  },
  {
    path: '/routine/:id',
    name: 'RoutineDetailPage',
    component: () => import('@/views/routine/Detail.vue'),
  },
  {
    path: '/routine/:type/:id',
    name: 'RoutineCopyPage',
    component: () => import('@/views/routine/Detail.vue'),
  },
  {
    path: '/push',
    name: 'PushPage',
    component: () => import('@/views/push/index.vue'),
  },
  {
    path: '/push/:id',
    name: 'PushDetailPage',
    component: () => import('@/views/push/Detail.vue'),
  },
  {
    path: '/push/:type/:id',
    name: 'PushCopyPage',
    component: () => import('@/views/push/Detail.vue'),
  },
  {
    path: '/email',
    name: 'EmailPage',
    component: () => import('@/views/email/index.vue'),
  },
  {
    path: '/email/:id',
    name: 'EmailDetailPage',
    component: () => import('@/views/email/Detail.vue'),
  },
  {
    path: '/email/:type/:id',
    name: 'EmailCopyPage',
    component: () => import('@/views/email/Detail.vue'),
  },
  {
    path: '/notice',
    name: 'NoticePage',
    component: () => import('@/views/notice/index.vue'),
  },
  {
    path: '/notice/:id',
    name: 'NoticeDetailPage',
    component: () => import('@/views/notice/Detail.vue'),
  },
  {
    path: '/inquiry',
    name: 'InquiryPage',
    component: () => import('@/views/inquiry/index.vue'),
  },
  {
    path: '/inquiry/search/:nickname',
    name: 'InquirySearchPage',
    component: () => import('@/views/inquiry/index.vue'),
  },
  {
    path: '/inquiry/:id',
    name: 'InquiryDetailPage',
    component: () => import('@/views/inquiry/Detail.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach(async (to, from, next) => {
  if (getCookie('session')) {
    if (to.meta.unauthorized) {
      sessionStorage.setItem('pathToLoadUnAuth', to.path)
      sessionStorage.setItem('pathFromLoadUnAuth', from.path)
      return next('/home')
    } else {
      sessionStorage.setItem('pathToLoadAuth', to.path)
      sessionStorage.setItem('pathFromLoadAuth', from.path)
      return next()
    }
  } else {
    if (to.meta.unauthorized) {
      sessionStorage.setItem('pathToLoadAuth', to.path)
      sessionStorage.setItem('pathFromLoadAuth', from.path)
      return next()
    } else {
      sessionStorage.setItem('pathToLoadUnAuth', to.path)
      sessionStorage.setItem('pathFromLoadUnAuth', from.path)
      return next('/')
    }
  }
})

export default router
