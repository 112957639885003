<template>
  <div class="popup">
    <div class="popup__bg" @click="$emit(`openClick`)"></div>
    <div class="popup__inner" v-if="innerType">
      <div class="popup__title">삭제되었습니다.</div>
      <div class="popup__under">
        <button class="popup__button" @click="$emit(`openClick`)">확인</button>
      </div>
    </div>
    <div class="popup__inner" v-else-if="loginType">
      <div class="popup__title">
        <p class="mb8">아이디 또는 비밀번호가 일치하지 않습니다.</p>
        <p>확인 후 다시 입력해주세요.</p>
      </div>
      <div class="popup__under">
        <button class="popup__button" @click="$emit(`openClick`)">확인</button>
      </div>
    </div>
    <div class="popup__inner" v-else-if="checkType">
      <div class="popup__title" v-text="contents"></div>
      <div class="popup__under">
        <button class="popup__button" @click="$emit(`openClick`)">확인</button>
      </div>
    </div>
    <div class="popup__inner" v-else-if="errorType">
      <div class="popup__title">
        <p>오류로 인해 등록되지 않았습니다.</p>
        <p>다시 확인해주세요.</p>
      </div>
      <div class="popup__under">
        <button class="popup__button" @click="$emit(`openClick`)">확인</button>
      </div>
    </div>
    <div class="popup__inner" v-else-if="sendType">
      <div class="popup__title" v-text="contents"></div>
      <div class="popup__under">
        <button
          class="popup__button popup__button--delete"
          @click="$emit(`openClick`)"
        >
          취소하기
        </button>
        <button class="popup__button" @click="$emit(`buttonClick`)">
          등록하기
        </button>
      </div>
    </div>
    <div
      class="popup__inner"
      v-else-if="imageType"
      style="width: 660px; min-width: 660px; height: 520px; min-height: 520px"
    >
      <div class="popup__contents">
        <div class="popup__close" @click="$emit(`openClick`)">
          <img src="@/assets/img/close.svg" />
        </div>
      </div>
      <div class="upload-image">
        <div class="upload-image__preview"><img :src="inputImg" /></div>
      </div>
    </div>
    <div class="popup__inner" v-else-if="deleteType">
      <div class="popup__title" v-text="contents"></div>
      <div class="popup__under">
        <button
          class="popup__button popup__button--delete"
          @click="$emit(`openClick`)"
        >
          취소하기
        </button>
        <button class="popup__button" @click="$emit(`buttonClick`)">
          삭제하기
        </button>
      </div>
    </div>
    <div class="popup__inner" v-else-if="logoutType">
      <div class="popup__title">로그아웃하시겠습니까?</div>
      <div class="popup__under">
        <button
          class="popup__button popup__button--delete"
          @click="$emit(`openClick`)"
        >
          취소하기
        </button>
        <button class="popup__button" @click="$emit(`buttonClick`)">
          로그아웃
        </button>
      </div>
    </div>
    <div class="popup__inner" v-else>
      <div class="popup__title">삭제하겠습니까?</div>
      <div class="popup__under">
        <button class="popup__button" @click="$emit(`buttonClick`)">확인</button
        ><button
          class="popup__button popup__button--delete"
          @click="$emit(`openClick`)"
        >
          취소
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PopupModal',
  props: [
    'innerType',
    'contents',
    'checkType',
    'loginType',
    'logoutType',
    'deleteType',
    'sendType',
    'errorType',
    'imageType',
    'inputImg',
  ],
  emits: ['openClick', 'buttonClick'],
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.popup,
.popup__bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.popup {
  &__bg {
    background: rgba(0, 0, 0, 0.5);
  }
  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    min-width: 450px;
    height: 200px;
    min-height: 200px;
    background: #fff;
    border-radius: 8px;
    border: solid 1px #ececec;
    text-align: center;
    font-size: 18px;
    z-index: 999;
    padding: 30px;
    line-height: 30px;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  &__title {
    width: 100%;
    word-break: keep-all;
  }
  &__close {
    float: right;
    width: 30px;
    height: 30px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  &__button {
    width: 120px;
    height: 45px;
    margin: 0 5px;
    background: #6b4ead;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;

    &--delete {
      background: #fff;
      color: #1f3d687c;
      border: 2px solid #1f3d6825;
      font-weight: 600;
    }
  }
}

.upload-image {
  height: 100%;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
  &__preview {
    width: 400px;
    height: 400px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: auto;
      max-width: 500px;
      max-height: 400px;
      border-radius: 10px;
    }
  }
}
</style>
