import { push } from '@/api'

const PUSH_DATA = () => {
  return {
    pushContent: {
      title: '',
      content: '',
      isLink: '0',
      link: '',
      memo: '',
    },
    pushInfo: {
      category: '',
      sendStatus: undefined,
      sendTime: '',
    },
  }
}

const state = {
  push: {
    pushContent: {
      title: '',
      content: '',
      isLink: '',
      link: '',
      memo: '',
    },
    pushInfo: {
      category: '',
      sendStatus: '',
      sendTime: '',
    },
  },
}

const mutations = {
  INIT_PUSH_DATA(state) {
    state.push = PUSH_DATA()
  },
  SET_PUSH_DETAIL(state, detail) {
    state.push = detail
  },
}

const getters = {}

const actions = {
  FETCH_PUSH_LIST({}, pushData) {
    return push.pushList(pushData).then((data) => {
      return data
    })
  },
  FETCH_PUSH_DETAIL({}, pushData) {
    return push.pushDetail(pushData).then((data) => {
      return data
    })
  },
  WRITE_PUSH_DETAIL({}, pushData) {
    return push.pushWrite(pushData).then((data) => {
      return data
    })
  },
  UPDATE_PUSH_DETAIL({}, pushData) {
    return push.pushUpdate(pushData).then((data) => {
      return data
    })
  },
  DELETE_PUSH_DETAIL({}, pushData) {
    return push.pushDelete(pushData).then((data) => {
      return data
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
// export default { actions }
