import { content } from '@/api'

const CONTENT_DATA = () => {
  return {
    content: {
      status: '',
      category: '',
      title: '',
      tag: '',
      sendDate: '',
      imgUrl: [],
      relate: [],
    },
    relateList: [],
    imgUrlList: [],
  }
}

const state = {
  content: {
    status: '',
    category: '',
    title: '',
    tag: '',
    sendDate: '',
    imgUrl: [],
    relate: [],
  },
  relateList: [],
  imgUrlList: [],
}

const mutations = {
  INIT_CONTENT_DATA(state) {
    state.content = CONTENT_DATA().content
    state.relateList = CONTENT_DATA().relateList
    state.imgUrlList = CONTENT_DATA().imgUrlList
  },
  SET_CONTENT_IMGURL(state, imgUrlList) {
    state.imgUrlList = imgUrlList
  },
  SET_CONTENT_IMGURL_CHECK(state, imgUrlList) {
    state.content.imgUrl = imgUrlList
  },
  SET_CONTENT_RELATE(state, relate) {
    state.relateList = relate
  },
  SET_CONTENT_STATUS(state, status) {
    state.content.status = status
  },
  SET_CONTENT_DETAIL(state, detail) {
    state.content = detail
  },
}

const getters = {}

const actions = {
  FETCH_CONTENT_LIST({}, contentData) {
    return content.contentList(contentData).then((data) => {
      return data
    })
  },
  FETCH_CONTENT_DETAIL({}, contentData) {
    return content.contentDetail(contentData).then((data) => {
      return data
    })
  },
  SEARCH_CONTENT_RELATE({}, contentData) {
    return content.contentRelateSearch(contentData).then((data) => {
      return data
    })
  },
  WRITE_CONTENT_DETAIL({}, contentData) {
    return content.contentWrite(contentData).then((data) => {
      return data
    })
  },
  UPDATE_CONTENT_DETAIL({}, contentData) {
    return content.contentUpdate(contentData).then((data) => {
      return data
    })
  },
  DELETE_CONTENT_DETAIL({}, contentData) {
    return content.contentDelete(contentData).then((data) => {
      return data
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
// export default { actions }
