import { notice } from '@/api'

const NOTICE_DATA = () => {
  return {
    content: '',
    memo: '',
    status: undefined,
    title: '',
  }
}
const state = {
  notice: {
    content: '',
    memo: '',
    status: '',
    title: '',
  },
}

const mutations = {
  INIT_NOTICE_DATA(state) {
    state.notice = NOTICE_DATA()
  },
  SET_NOTICE_DETAIL(state, detail) {
    state.notice = detail
  },
}

const getters = {}

const actions = {
  FETCH_NOTICE_LIST({}, noticeData) {
    return notice.noticeList(noticeData).then((data) => {
      return data
    })
  },
  FETCH_NOTICE_DETAIL({}, noticeData) {
    return notice.noticeDetail(noticeData).then((data) => {
      return data
    })
  },
  WRITE_NOTICE_DETAIL({}, noticeData) {
    return notice.noticeWrite(noticeData).then((data) => {
      return data
    })
  },
  UPDATE_NOTICE_DETAIL({}, noticeData) {
    return notice.noticeUpdate(noticeData).then((data) => {
      return data
    })
  },
  DELETE_NOTICE_DETAIL({}, noticeData) {
    return notice.noticeDelete(noticeData).then((data) => {
      return data
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
// export default { actions }
