<template>
  <div class="header">
    <div class="header__logo">
      <img
        src="@/assets/img/logo.png"
        alt="logo"
        @click="$router.push('/home')"
      />
    </div>
    <div class="header__user" v-if="!headerNone">
      <div class="header__logout" @click="logoutPopup = true">로그아웃</div>
    </div>
    <popup-modal
      logoutType="true"
      v-if="logoutPopup"
      @open-click="logoutPopup = false"
      @button-click="adminLogout"
    ></popup-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { deleteCookies } from '@/utils/cookie'
import PopupModal from '@/components/PopupModal.vue'
export default {
  name: 'WebHeader',
  props: ['headerNone'],
  components: { PopupModal },
  data() {
    return {
      logoutPopup: false,
    }
  },
  computed: {},
  methods: {
    ...mapMutations('auth', ['INIT_DATA']),
    ...mapActions('auth', ['SINGOUT']),
    async adminLogout() {
      try {
        await this.SINGOUT().then((data) => {
          if (data.result && data.result === true) {
            deleteCookies()
            this.INIT_DATA()
            this.$router.push('/')
          }
        })
      } catch (e) {
        deleteCookies()
        this.INIT_DATA()
        this.$router.push('/')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  line-height: 70px;
  border-bottom: solid 1px #ececec;
  background: #fff;
  z-index: 500;
  min-width: 1500px;
  &__logo {
    width: 18%;
    min-width: 250px;
    img {
      cursor: pointer;
      width: 130px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__user {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-right: 120px;
  }
  &__logout {
    cursor: pointer;
    color: #a8a8a8;
    font-size: 16px;
  }
}
</style>
