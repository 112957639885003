import { routine } from '@/api'

const ROUTINE_DATA = () => {
  return {
    mainRoutine: {
      content: '',
      title: '',
    },
    memo: '',
    partnerRoutine: {
      content: '',
      title: '',
      tag: '',
    },
    routineInfo: {
      bigCate: '0',
      period: '0',
      smallCate: '0',
    },
  }
}

const state = {
  routine: {
    mainRoutine: {
      content: '',
      title: '',
    },
    memo: '',
    partnerRoutine: {
      content: '',
      title: '',
      tag: '',
    },
    routineInfo: {
      bigCate: '0',
      period: '0',
      smallCate: '0',
    },
  },
}

const mutations = {
  INIT_ROUTINE_DATA(state) {
    state.routine = ROUTINE_DATA()
  },
  SET_ROUTINE_DETAIL(state, detail) {
    state.routine = detail
  },
}

const getters = {}

const actions = {
  FETCH_ROUTINE_LIST({}, routineData) {
    return routine.routineList(routineData).then((data) => {
      return data
    })
  },
  FETCH_ROUTINE_DETAIL({}, routineData) {
    return routine.routineDetail(routineData).then((data) => {
      return data
    })
  },
  WRITE_ROUTINE_DETAIL({}, routineData) {
    return routine.routineWrite(routineData).then((data) => {
      return data
    })
  },
  UPDATE_ROUTINE_DETAIL({}, routineData) {
    return routine.routineUpdate(routineData).then((data) => {
      return data
    })
  },
  DELETE_ROUTINE_DETAIL({}, routineData) {
    return routine.routineDelete(routineData).then((data) => {
      return data
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
// export default { actions }
