import { user } from '@/api'

const state = {}

const mutations = {}

const getters = {}

const actions = {
  FETCH_USER_LIST({}, userData) {
    return user.userList(userData).then((data) => {
      return data
    })
  },
  FETCH_USER_DETAIL({}, userData) {
    return user.userDetail(userData).then((data) => {
      return data
    })
  },
  DELETE_USER_DETAIL({}, userData) {
    return user.userDelete(userData).then((data) => {
      return data
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
// export default { actions }
