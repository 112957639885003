<template>
  <router-view></router-view>
</template>

<script>
import WebTemplate from './layouts/WebTemplate.vue'
export default {
  name: 'App',
  components: {
    WebTemplate,
  },
}
</script>

<style lang="scss"></style>
